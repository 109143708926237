import React, { useContext } from 'react'
import styled, { ThemeContext, DefaultTheme, css } from 'styled-components'
import { UseFormRegisterReturn } from 'react-hook-form'
import { InputConfig, SelectOptions } from 'components/input/input'
import { InputLabel, borderColor } from 'components/input-text/input-text'
import { SvgCaret } from 'components/svgs/svg-caret'
import { transition } from 'theme/utils'

type SelectProps = {
	config: InputConfig
	value?: string
	defaultValue?: string
	options: SelectOptions[]
	hasError?: boolean
	register?: UseFormRegisterReturn
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const Select = ({ config, value, defaultValue, options, hasError = false, register, onChange }: SelectProps) => {
	const themeContext = useContext(ThemeContext)
	const { label, placeholder, ...inputConfig } = config

	return (
		<>
			{config && config.label && <InputLabel {...(config.id && { htmlFor: config.id })}>{config.label}</InputLabel>}
			<SelectWrapper>
				<SelectElement
					{...inputConfig}
					value={value}
					defaultValue={defaultValue}
					ref={register}
					$hasError={hasError}
					onChange={onChange}>
					{options.map((option, index) => {
						return (
							<option key={index} value={option.value} disabled={option.disabled}>
								{option.label}
							</option>
						)
					})}
				</SelectElement>
				<SelectIcon suffix="-select" stroke={themeContext ? themeContext.color.primary : ''} />
			</SelectWrapper>
		</>
	)
}

const SelectWrapper = styled.div`
	position: relative;
`

const SelectStyle = (props: { theme: DefaultTheme }, hasError = false) => {
	const backgroundColor = props.theme.isDark ? 'rgba(255, 255, 255, 0.2)' : props.theme.colors.white

	return css`
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 11px 25px 7px 11px;
		margin: 0;
		font-family: ${props => props.theme.fonts.primary};
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		color: ${props => props.theme.text.primary};
		appearance: none;
		background-color: ${props => (props.theme.isDark ? 'transparent' : props.theme.colors.white)};
		background-image: linear-gradient(to bottom, ${backgroundColor} 0%, ${backgroundColor} 100%);
		background-repeat: repeat;
		background-position: 0 0;
		background-size: 100%;
		border-color: ${props => borderColor(props, hasError)};
		border-style: solid;
		border-width: 1px;
		border-radius: 2px;
		box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
		transition: ${transition('box-shadow')}, ${transition('border')};

		/* Hide arrow icon in IE browsers */
		&::-ms-expand {
			display: none;
		}

		&:hover {
			box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
		}

		&:focus {
			border-color: ${props => props.theme.color.primary};
			outline: none;
		}

		&:-moz-focusring {
			color: transparent;
			text-shadow: 0 0 0 #000;
		}

		/* Set options to normal weight */
		& option {
			font-weight: normal;
		}
	`
}

const SelectElement = styled.select<{ $hasError: boolean; ref: any }>`
	${props => SelectStyle(props, props.$hasError)}
`

const SelectIcon = styled(SvgCaret)`
	position: absolute;
	top: 50%;
	right: 5px;
	pointer-events: none;
	transform: translateY(-50%);
`
