import React from 'react'
import styled from 'styled-components'
import { UseFormRegisterReturn } from 'react-hook-form'
import { InputConfig } from 'components/input/input'
import { inputStyle, InputLabel, InputError } from 'components/input-text/input-text'

type TextareaProps = {
	config: InputConfig
	value?: string
	hasError?: boolean
	errorMessage?: string
	register?: UseFormRegisterReturn
	onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
}

const TextareaElement = styled.textarea<{ $hasError?: boolean }>`
	${props => inputStyle(false, false, props.$hasError)}
	height: 128px;
	max-height: none;
`

export const Textarea = ({ config, value, hasError, errorMessage, register, onBlur }: TextareaProps) => {
	const { label, required, ...inputConfig } = config

	return (
		<>
			{config && config.label && (
				<InputLabel {...(config.id && { htmlFor: config.id })}>
					{config.label}
					{required && <b>*</b>}
				</InputLabel>
			)}
			<TextareaElement {...inputConfig} $hasError={hasError} onBlur={onBlur} {...register}>
				{value}
			</TextareaElement>
			{errorMessage && <InputError>{errorMessage}</InputError>}
		</>
	)
}
