import {
	CategoryDataFragment,
	SubCategoryDataFragment,
	TopicDataFragment,
	SocialNetworksDataFragment,
	SimpleMenuItemDataFragment,
	CategoriesMenuItemDataFragment,
	PageCommonQuery,
	CommonPageDataFragment,
	NewsImageDataFragment,
} from 'generated/sdk'
import { TrafficConditionStatus, TrafficDesktopCondition } from 'components/traffic-desktop/traffic-desktop'
import { LinkItem } from 'components/primary-navigation/primary-navigation'
import { SecondaryLinkItem } from 'components/secondary-navigation/secondary-navigation'
import { PrimaryHeaderDesktopProps } from 'components/primary-header-desktop/primary-header-desktop'
import { PrimaryHeaderMobileProps } from 'components/primary-header-mobile/primary-header-mobile'
import { FooterDesktopProps } from 'components/footer-desktop/footer-desktop'

export const categoryPageDataMapper = (data: CommonPageDataFragment) => {
	const nameProperty = 'title'
	const urlProperty = 'url'
	return {
		label: data[nameProperty],
		href: data[urlProperty],
	}
}

export const categoryDataMapper = (data: CategoryDataFragment) => {
	const nameProperty = 'name'
	const urlProperty = 'slug'
	return {
		label: data[nameProperty],
		href: data[urlProperty],
	}
}

export const subCategoryDataMapper = (data: SubCategoryDataFragment) => {
	return {
		...categoryDataMapper(data),
		parent: data.parent ? categoryDataMapper(data.parent) : null,
	}
}

export const topicDataMapper = (data: TopicDataFragment) => {
	const nameProperty = 'name'
	const descriptionProperty = 'description'
	return {
		title: data[nameProperty],
		description: data[descriptionProperty],
	}
}

export const trafficConditionDataMapper = (data: any): TrafficDesktopCondition[] => {
	const outJson = data[0]?.outjson || undefined

	if (!outJson) {
		return []
	}

	return Object.keys(outJson).map(key => {
		return {
			status: key as TrafficConditionStatus,
			streets: outJson[key].conteudo.map(({ topo }: any) => topo),
			count: outJson[key].cnt,
		}
	})
}

export const getRenditionUrl = (rendition?: NewsImageDataFragment['small']) => {
	return rendition?.url || ''
}

export const getSlideThumbnail = (image: NewsImageDataFragment, isMobile = false, isHero = false) => {
	return {
		src: getRenditionUrl(isMobile ? image.small : isHero ? image.large : image.medium),
		srcRetina: getRenditionUrl(isMobile ? image.medium : image.large),
		srcPlaceholder: image.placeholderHash,
		alt: image.title,
	}
}

export const socialNetworksDataMapper = (data?: SocialNetworksDataFragment | null) => {
	return {
		facebook: data?.facebook,
		youtube: data?.youtube,
		twitter: data?.twitter,
		instagram: data?.instagram,
	}
}

export const languageChooserDataMapper = (data: string[], language: string) => {
	return data.map(item => ({ href: `/${item}/`, label: item.toUpperCase(), isCurrent: item === language }))
}

export const simpleMenuLinksDataMapper = (data?: SimpleMenuItemDataFragment[]): LinkItem[] => {
	if (!data) {
		return []
	}
	return data.map(item => ({
		href: item.linkUrl || item.linkPage?.href || '',
		label: item.linkText || item.linkPage?.label || '',
	}))
}

export const categoriesMenuLinksDataMapper = (data?: CategoriesMenuItemDataFragment[]): SecondaryLinkItem[] => {
	if (!data) {
		return []
	}
	return data.map(item => ({
		href: item.linkUrl || item.linkPage?.href || '',
		label: item.linkText || item.linkPage?.label || '',
		...(item.linkPage && item.linkPage.subLinks.length > 0 && { subLinks: item.linkPage.subLinks }),
	}))
}

export const baseLayoutDataMapper = (data: PageCommonQuery, language: string) => {
	const headerProps: PrimaryHeaderDesktopProps & PrimaryHeaderMobileProps = {
		languages: languageChooserDataMapper(data.languages, language),
		primaryLinks: simpleMenuLinksDataMapper(data.primaryMenu?.menuItems),
		secondaryLinks: categoriesMenuLinksDataMapper(data.categoriesMenu?.menuItems),
		secondaryLinksTitle: data.categoriesMenu?.title || '',
		weather: { ...data.weather, suffix: '' },
	}
	const footerProps: FooterDesktopProps = {
		social: socialNetworksDataMapper(data.socialNetworks),
		primaryLinks: simpleMenuLinksDataMapper(data.discoveringMenu?.menuItems),
		primaryLinksTitle: data.discoveringMenu?.title || '',
		secondaryLinks: categoriesMenuLinksDataMapper(data.categoriesMenu?.menuItems),
		secondaryLinksTitle: data.categoriesMenu?.title || '',
		otherLinks: simpleMenuLinksDataMapper(data.aboutMenu?.menuItems),
		otherLinksTitle: data.aboutMenu?.title || '',
		copyright: data.copyright,
		policiesLinks: simpleMenuLinksDataMapper(data.policiesMenu?.menuItems),
	}

	return { headerProps, footerProps }
}
