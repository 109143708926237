import React from 'react'
import { Language } from 'generated/sdk'
import { useLocationContext } from 'providers/location-provider/location-provider'

const TITLE_TEXT = {
	[Language.Pt]: (inverted = false) => (inverted ? 'Seta para cima' : 'Seta para baixo'),
	[Language.En]: (inverted = false) => (inverted ? 'Arrow up' : 'Arrow down'),
}

export const SvgCaret: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, stroke = '#0F2F7F', rotate = 0, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-calendar' + suffix

	return (
		<svg width={24} height={24} role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language](rotate === 180)}</title>
			<path
				transform={`rotate(${rotate} 12 12)`}
				fill="none"
				stroke={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1.5}
				d="M17 10l-4.5 5L8 10"
			/>
		</svg>
	)
}
