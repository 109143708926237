export const formatMapper = (format: string | string[]) => {
	if (format instanceof Array) {
		return format.map(format => format.split('/')[1].toUpperCase()).join(', ')
	}

	return format.split('/')[1].toUpperCase()
}

export const bytesToSize = (bytes: number, separator = '', postFix = '') => {
	if (bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
		const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1)
		return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`
	}

	return 'n/a'
}
