import React from 'react'
import styled from 'styled-components'
import { UseFormRegisterReturn } from 'react-hook-form'
import { InputText } from 'components/input-text/input-text'
import { Textarea } from 'components/textarea/textarea'
import { InputFile } from 'components/input-file/input-file'
import { InputCheckbox } from 'components/input-checkbox/input-checkbox'
import { Select } from 'components/select/select'

export enum InputType {
	input = 'input',
	textarea = 'textarea',
	checkbox = 'checkbox',
	file = 'file',
	select = 'select',
}

export type InputConfig = {
	className?: string
	type?: string
	label?: string
	id: string
	name: string
	placeholder?: string
	required?: boolean
	disabled?: boolean
}

export type SelectOptions = {
	value: string
	label: string
	disabled?: boolean
}

export type InputProps = {
	type: InputType
	config: InputConfig
	value?: string
	defaultValue?: string
	options?: SelectOptions[]
	accept?: string | string[]
	multiple?: boolean
	maxSize?: number
	showMagnifier?: boolean
	compressed?: boolean
	noMargin?: boolean
	hasError?: boolean
	errorMessage?: string
	clearCheckbox?: boolean
	clearFiles?: boolean
	register?: UseFormRegisterReturn
	handleFileDrop?: (name: string, files: File[]) => void
	onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
	onCheckboxChange?: (value: boolean) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
	onReset?: (name: string) => void
}

const InputWrapper = styled.div<{ $noMargin: boolean; $hasErrorMessage: boolean }>`
	margin: ${props => (props.$noMargin ? '0' : props.$hasErrorMessage ? '0 0 6px' : '0 0 24px')};
`

const InputComponent = ({
	type,
	config,
	value,
	defaultValue,
	options = [],
	accept,
	multiple,
	maxSize,
	showMagnifier,
	compressed,
	noMargin = false,
	hasError,
	errorMessage,
	clearCheckbox,
	clearFiles,
	register,
	handleFileDrop,
	onChange,
	onBlur,
	onReset,
	onCheckboxChange,
}: InputProps) => {
	let element = null

	switch (type) {
		case InputType.textarea:
			element = (
				<Textarea config={config} hasError={hasError} errorMessage={errorMessage} register={register} onBlur={onBlur} />
			)
			break
		case InputType.checkbox:
			element = (
				<InputCheckbox
					config={config}
					hasError={hasError}
					errorMessage={errorMessage}
					register={register}
					clearCheckbox={clearCheckbox}
					onChange={onCheckboxChange}
				/>
			)
			break
		case InputType.select:
			element = (
				<Select
					config={config}
					value={value}
					defaultValue={defaultValue}
					options={options}
					hasError={hasError}
					register={register}
					onChange={onChange}
				/>
			)
			break
		case InputType.file:
			element = (
				<InputFile
					config={config}
					acceptedFiles={accept}
					multiple={multiple}
					maxSize={maxSize}
					hasError={hasError}
					errorMessage={errorMessage}
					clearFiles={clearFiles}
					handleFileDrop={handleFileDrop}
					onReset={onReset}
				/>
			)
			break
		default:
			element = (
				<InputText
					config={config}
					value={value}
					compressed={compressed}
					showMagnifier={showMagnifier}
					hasError={hasError}
					errorMessage={errorMessage}
					register={register}
					onChange={onChange}
					onBlur={onBlur}
				/>
			)
	}

	return (
		<InputWrapper $noMargin={noMargin} $hasErrorMessage={!!errorMessage}>
			{element}
		</InputWrapper>
	)
}

export const Input = React.memo(InputComponent)
