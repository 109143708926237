import React from 'react'
import { useLocationContext } from 'providers/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: (isCross = false) => (isCross ? 'Remover' : 'Adicionar'),
	[Language.En]: (isCross = false) => (isCross ? 'Remove' : 'Add'),
}

export const SvgPlusSign: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, fill = '#0F2F7F', rotate = 0, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-plus-sign' + suffix

	return (
		<svg width={18} height={18} aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language](rotate === 45)}</title>
			<g fill="none" fillRule="evenodd" transform={`rotate(${rotate} 9 9)`}>
				<circle cx={9} cy={9} r={8.5} fill={fill} stroke={fill} />
				<path stroke="#FFF" strokeLinecap="round" strokeWidth={1.5} d="M9 5v8m4-4H5" />
			</g>
		</svg>
	)
}
